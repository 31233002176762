import axios from 'axios';
import { apiServiceV2, dispatcherService } from '@/api/api.service';

const baseUrl = process.env.VUE_APP_BASE_URL;

// const operatorId = 4;

export default {

  login(params) {
    return dispatcherService.post('auth/get-pfdo-sign-url', params);
  },

  // restorePassword(username, captchaCode) {
  //   return apiServiceV2.post(`/main-page/restore-password/${operatorId}`, {
  //     username,
  //     captcha: captchaCode,
  //   });
  // },

  // капча с дипетчера
  getCaptcha() {
    return dispatcherService.get('/captcha');
  },

  getOwn() {
    return apiServiceV2.get('/own');
  },

  getToken(username, password, captcha) {
    return apiServiceV2.post(
      '/authorization',
      {
        username,
        password,
        captcha,
      },
    );
  },

  refreshToken(token) {
    return axios.post(
      `${baseUrl}oauth2/token`,
      {
        grant_type: 'refresh_token',
        refresh_token: token,
        client_id: 'frontend',
        client_secret: null,
      },
    );
  },
};
