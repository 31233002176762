<template lang="pug">
v-container.sign-in
  password-recover-dialog(v-model="showPasswordRecoverDialog")

  v-layout(row wrap).sign-in__form
    v-flex(lg5 md5 xl4)
      v-card(flat)
        v-card-text
          h1.section-title.text-center Вход в систему
          v-form(v-model="isFormValid" autocomplete="off")
            v-text-field(
              v-model="username"
              name="username"
              label="Имя пользователя"
              filled
              autocomplete="none"
              color="primary"
              :rules="rules"
              @keyup.enter="signIn"
            )
            v-text-field(
              v-model="password"
              filled
              autocomplete="new-password"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              label="Пароль"
              name="password"
              :type="showPassword ? 'text' : 'password'"
              color="primary"
              :rules="rules"
              @keyup.enter="signIn"
              @click:append="showPassword = !showPassword"
            )
            captcha-input.mb-3(
              v-if="showCaptcha"
              v-model="captcha.value"
              @done="signIn"
              @token="captcha.key = $event"
            )
            v-layout(row wrap).sign-in__actions
              v-flex(xs3)
                v-btn.signin-button(
                  depressed
                  @click="signIn"
                  color="primary"
                  :disabled="!isFormValid"
                  :loading="isLoading"
                ) Войти
              v-flex(xs9 align-self-center justify-self-end).extend
                .extend-action(@click="showPasswordRecoverDialog = true")
                  span Восстановление пароля
              v-flex(xs12 v-if="checkSignInPage")
                a.app-link.primary--text(
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=ru.pfdo.app.certificate"
                )
                  span Скачать приложение
                    br
                    |  «ОНФ. Сертификат»
                  v-img.ml-2(
                    max-width="100"
                    :src="require('@/assets/images/google-play.png')"
                  )
</template>

<script>
import CaptchaInput from '@/components/inputs/CaptchaInput';

import PasswordRecoverDialog from '../modals/PasswordRecoverDialog';
import authService from '@/services/auth';

export default {
  name: 'SignInForm',
  components: {
    PasswordRecoverDialog,
    CaptchaInput,
  },
  data() {
    return {
      username: null,
      password: null,
      captcha: {},
      rules: [
        (value) => !!value || 'Обязательное поле.',
      ],
      isFormValid: false,
      isLoading: false,
      showPassword: false,
      showSignUpDialog: false,
      showPasswordRecoverDialog: false,
      showCaptcha: false,
    };
  },
  computed: {
    /**
     * отображение ссылки на приложение только
     * на странице входа в личный кабинет
     * @returns {boolean} - true: отображать
     */
    checkSignInPage() {
      return this.$route.name === 'signin';
    },
  },
  methods: {
    signIn() {
      this.isLoading = true;
      this.$rHandler(
        authService.login({ username: this.username, password: this.password, captcha: this.captcha }),
        ({ data }) => {
          window.open(data.data.authorization_url);
        },
        ({ data }) => {
          const CAPTCHA_ERRORS = ['CRQ01', 'CRQ02'];
          if (CAPTCHA_ERRORS.includes(data.result_code)) {
            this.$toasted.info('Введите корректно код с картинки', { duration: 3000 });
            this.showCaptcha = true;
          } else {
            this.$toasted.error('Пользователь с таким логином и паролем не найден', { duration: 3000 });
          }
          return data;
        },
      ).finally(() => {
        this.isLoading = false;
        this.$root.$emit('refreshCaptcha');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-in {
  padding: 130px 0;

  &__form {
    justify-content: center;
  }
  &__actions {
    .signin-button {
      margin: 0;
    }
    .extend {
      text-align: right;

      .extend-action {
        display: inline;
        cursor: pointer;
        padding-left: 10px;
      }
    }
  }

  .app-link {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $dark-color;

  }

}

@media (max-width: 375px) {
.sign-in {
  &__actions {
    .extend .extend-action {
        display: block;
        cursor: pointer;
        padding-left: 10px;
        color: $dark-color;
      }
    }
  }
}
</style>
