<template lang="pug">
v-dialog(v-model="show" max-width="500")
  .dialog
    v-toolbar(color="primary")
      v-toolbar-items
        v-icon(@click="show=false") close

      v-toolbar-title
        | Восстановление пароля

    v-card.contract-request(flat)
      v-card-text
        v-text-field(
          v-model="username"
          label="Номер сертификата или логин"
          :error-messages="validationErrors.username"
        )
        captcha-input(v-if="show" v-model="captchaCode" @done="restorePassword")

      v-card-text(v-if="validationErrors.message && validationErrors.message.length")
        v-alert(type="error" outlined)
          span(v-html="validationErrors.message.join(', ')")

      v-card-text.actions
        v-btn(@click="show=false" depressed) Закрыть
        v-btn(@click="restorePassword" color="primary"  depressed) Отправить подтверждение на почту

</template>

<script>
import AuthService from '@/services/auth';

import CaptchaInput from '@/components/inputs/CaptchaInput';

export default {
  name: 'PasswordRecoverDialog',
  components: {
    CaptchaInput,
  },
  props: {
    value: { type: Boolean, required: true },
  },
  data() {
    return {
      captchaCode: '',
      username: '',
      validationErrors: {},
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    restorePassword() {
      this.validationErrors = {};
      this.$rHandler(
        AuthService.restorePassword(this.username, this.captchaCode),
        () => {
          const msg = 'Инструкции о дальнейших действиях отправлены вам на почту';
          this.$toasted.success(msg, { duration: 5000 });
          this.show = false;
        },
        (response) => {
          if (response.data.result_code === 'VAL01') {
            this.validationErrors = response.data.data;
            return response;
          }
          return null;
        },
      );
      this.$root.$emit('refreshCaptcha');
    },
  },
};
</script>
