/*
 * https://gitlab.pfdo.ru/pfdo/reestr/-/wikis/home
 */
import { aisRegistryService } from '@/api/api.service';


export default {
  createCertificate(payloads) {
    return aisRegistryService.post('/certificate-application', payloads);
  },

  getLicensePDF() {
    return aisRegistryService.get('/license/view');
  },

  getRulesPDF() {
    return aisRegistryService.get('/rules/view');
  },

  getRegulationPDF(munId) {
    return aisRegistryService.get(`/regulation/view?munId=${munId}`);
  },

  getAgreementPDF(munId) {
    return aisRegistryService.get(`/agreement/${munId}`);
  },

  getCaptcha() {
    return aisRegistryService.get('/captcha');
  },

};
