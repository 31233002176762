<template lang="pug">
  v-layout(row wrap)
    v-flex
      v-text-field(
        v-model="inputValue"
        label="Код с картинки"
        v-bind="$attrs"
        @keyup.enter="$emit('done')"
      )
    v-flex
      v-img(
        :class="{ blured: loading }"
        :src="captchaSrc"
        min-width="100"
        contain
      )
</template>

<script>
import AuthService from '@/services/auth';
import AisRegistryService from '@/services/ais-reestrs-service';

export default {
  props: {
    value: { type: String, default: null },
    aisRegistry: { type: Boolean, default: false },
  },
  data() {
    return {
      captchaSrc: '',
      loading: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    inputValue(val) {
      if (val) {
        this.inputValue = val.toUpperCase();
      }
    },
  },
  created() {
    this.init();
    this.$root.$on('refreshCaptcha', this.loadCaptcha);
  },
  methods: {
    init() {
      this.loadCaptcha();
    },
    loadCaptcha() {
      const service = this.aisRegistry ? AisRegistryService : AuthService;

      this.loading = true;
      this.$rHandler(
        service.getCaptcha(),
        ({ data }) => {
          this.inputValue = null;
          if (this.aisRegistry) {
            this.captchaSrc = `data:image/png;base64,${data.data.image}`;
            this.$emit('token', data.data.token);
          } else {
            this.captchaSrc = data.data.image_data;
            this.$emit('token', data.data.key);
          }
        },
      ).finally(() => { this.loading = false; });
    },
  },
};
</script>
