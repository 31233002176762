<template lang="pug">
  sign-in-form
</template>

<script>
import SignInForm from '../components/SignInForm';

export default {
  components: { SignInForm },
};
</script>
